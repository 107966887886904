.sl-prose p a {
  color: #0055dc !important;
  text-decoration: underline;
}

.feedback-container {
  background: white;
  border-top-width: 1px;
  border-right-width: 1px;
  color: #0055dc;
  padding: 10px 15px;
  position: absolute;
  text-decoration: none;
  left: 0;
  bottom: 0;
  z-index: 99;
  width: 300px;
}

@media screen and (max-width: 780px) {
  .feedback-container {
    position: fixed;
    width: 100%;
  }

  .sl-elements {
    padding: 15px;
  }
}

.feedback-widget {
  align-items: center;
  display: flex;
}

.feedback-widget span {
  border-bottom: 1px solid rgba(0, 85, 220, 0.35);
}

.feedback-widget svg {
  margin-left: 8px;
  width: 15px;
  height: 15px;
}

/* loading */
.loading {
  display: flex;
  height: 100%;
}

.loading .sidebar {
  width: 300px;
  border-right-width: 1px;
  padding: 2rem;
}

.loading .sidebar svg {
  max-width: 300px;
}

.loading .sidebar svg:not(:first-child) {
  margin: 2rem 0;
}

.loading .content {
  flex: 1;
  padding: 2rem;
}

.loading .content svg {
  max-width: 300px;
}

.loading .content svg:not(:first-child) {
  margin: 2rem 0;
}

.sl-bg-canvas-100 {
  background-color: white;
}

.sl-bg-warning {
  background: #e06d10;
}

.sl-bg-warning.sl-text-body {
  color: #fff;
}

.sl-text-warning {
  color: #b6590d;
}

.sl-bg-primary-tint {
  background-color: rgba(146, 151, 155, 0.2);
}

.sl-text-warning {
  color: var(--color-text-warning);
  text-transform: capitalize;
}

/* app */
.sl-elements-api > div:first-child .sl-rounded-lg {
  border-radius: 0;
}

.sl-elements-api {
  --color-primary: #2c7cb0;
  --color-success: #398557;
  --color-warning: #da304c;
  --color-danger: #bf223c;
  --color-danger-dark: #d83b3b;
}

/* sidebar */
.sl-elements-api > div:first-child {
  max-width: 500px;
  padding-left: 0 !important;
  padding-top: 20px;
}

.sl-elements-api > div:first-child > a {
  display: none;
}

.sl-elements-api > div:first-child {
  margin-bottom: 34px;
}

/* Rest method in sidebar */
.ElementsTableOfContentsItem > div > div:last-child > div {
  border: 1px solid #eee;
  border-radius: 25px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 5px 10px;
}

.ElementsTableOfContentsItem > div.sl-bg-primary-tint > div:last-child > div {
  border: 1px solid rgba(146, 151, 155, 0.5);
}

/* container */
.sl-panel__titlebar {
  background-color: #f3f3f4;
  color: black;
}

.sl-panel__titlebar > div > span {
  color: #000;
  font-weight: bold;
}

.sl-panel__titlebar > div > div > button:first-child {
  color: #000;
  font-weight: bold;
}

.sl-panel__content-wrapper {
  background-color: #f3f3f4;
  color: black;
}

.TryItPanel {
  display: none;
}

.sl-text-body .sl-bg-success {
  color: #fff !important;
}

.sl-flex.sl-justify-end.sl-items-center.sl-px-1.sl-py-3 {
  display: none;
}

/* algolia */
button.DocSearch-Button {
  border: 1px solid var(--docsearch-muted-color);
  margin: 0;
  width: 100%;
}

.DocSearch-Screen-Icon svg {
  margin: 0 auto;
}

@media (max-width: 768px) {
  .DocSearch-Button-Placeholder {
    display: block;
  }
}
